// IMPORT
@import "breakpoint";
@import "_constants.scss";

* {
    box-sizing: border-box!important;
}
//ICONS
.icon {
    background-size: 16px!important;
    height: 16px;
    width: 16px;
    display: inline-block;
    
    &.search-icon {
        background: url('pic/search-icon.png');
    }

    &.back-icon {
        background: url('pic/left.svg');
    }

}

.menubutton {
    background: url('pic/menu-toggle.svg') no-repeat;
    background-size: contain;
    height: 24px;
    width:  24px;
    display: block;
}


//STYLE

//// Global
.mobile-padding {
    padding: 0 8px;
    @include breakpoint($nav-menu-show){
        padding: 0;
    }
}

////Menü
#header {
    background: #22386b;
    @include breakpoint($nav-menu-show){
        background: url('pic/header-bg.jpg') repeat-x 0 0 transparent;
        height: 103px;
    }
    .inner {
        position: relative;
        overflow: hidden;
        @include breakpoint($nav-menu-show){
            overflow: visible;
        }
    }
    .inner .logo a{
        background: url('pic/sprites.png') no-repeat -18px -16px transparent;
        height: 49px;
        width: 110px;
        margin-top: 11px;
        background-size: 412px;
        margin-left: 8px;
        @include breakpoint($nav-menu-show){
            background: url('pic/sprites.png') no-repeat -24px -20px transparent;
            width: 132px;
            height: 60px;
            margin-left: 0;
        }
    }
    
        /**********************
        keresés fejlécben
    **********************/

    .search-li{
        padding: 7px 0 7px 24px;
        @include breakpoint($nav-menu-show){
            width: auto!important;
            width: initial!important;
            float: left;
            padding-top: 27px;
            &:hover {
                background: none;
                box-shadow: none;
            }
        }
        .search-icon {
            margin-right: 16px;
        }
    }

    .search-div{
        display: inline-block;
        input {
            padding: 4px;
            width: 200px; 
        }
        * {
            font-size: 16px;
        }
        .inner-search-submit {
            display: none;
            @include breakpoint($nav-menu-show){
                display: inline-block;
            }
        }
        span.menu-icon.search-icon.search-submit{
            @include breakpoint($nav-menu-show){
                display: none;
            }
        }
        @include breakpoint($nav-menu-show){
            * {
                font-size: 12px;
            }
            input {
                padding: 0;
                padding: initial;
            }
        }
    }
    .search-div .text-div{
        background-color: #ffffff;
    }
    .search-div input[type='text']{
        border: none;
        color: #2C2C2E;
        vertical-align: middle;
    }
    .search-div a{
        background: url('pic/sprites.png') no-repeat -94px 0 transparent;
        cursor: pointer;
        display: inline-block;
        height: 16px;
        margin: 0 7px 0 3px;
        vertical-align: middle;
        width: 16px;
    }


    @include breakpoint($nav-menu-show) {
        .inner .logo a {
            display: inline-block;
        }
    }
    @include breakpoint($lg) {
        .search-div {
            display: block;
        }
    }

    .menu-toggle {
        padding: 18px;

        @include breakpoint($nav-menu-show){
            display: none;
        }
    }

    .menu {
        width: 100%;
        background: #4e69a6;
        clear: both;
        display: none;
        @include breakpoint($nav-menu-show){
            display: inline-block!important;
            width: auto;
            width: initial;
            background: none;
            float: right;
            clear: none;
            margin: 0;
        }

        
        ul {
            list-style-type: none;
            padding: 8px 0;
            @include breakpoint($nav-menu-show){
                padding: 0;
                margin: 0;
            }
            
            li {
                color: #FFFFFF;
                cursor: pointer;
                &:hover {
                    background: #385192;
                    box-shadow: inset 12px 0 28px -20px #000, inset -12px 0 28px -20px #000;
                }

                a.menupont {
                    display: block;
                    font-size: 20px;
                    padding: 16px 24px;
                    color: #fff;
                    position: relative;
                    

                    .kosar-db {
                        left: 36px;
                        top: 4px;
                        right: auto;
                        right: initial;
                        bottom: auto;
                        bottom: initial;
                    }

                    @include breakpoint($nav-menu-show){
                        padding: 23px 0 0;
                        height: 79px;
                        font-size: 12px;

                        .kosar-db {
                            left: auto;
                            left: initial;
                            // bottom: auto;
                            // bottom: initial;
                            right: 25px;
                            top: 8px;
                        }
                    }
                    
                    .menu-icon{
                        display: inline-block;
                        height: 16px;
                        width: 16px;
                        margin-right: 16px;
                        
                        @include breakpoint($nav-menu-show){
                            display: block;
                            margin: 0 auto 3px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                    
                    &:visited {
                        color: #fff;
                    }
                    
                }


                @include breakpoint($nav-menu-show){
                    display: inline-block;
                    
                    width: 100px;	
                    position: relative;
                    text-align: center;
                }
            }
        }

        
        .flag-li {
            &:hover {
                background: none;
                box-shadow: none;
            }
            @include breakpoint($nav-menu-show){
                width: auto;
                width: initial;
            }
        }

        .search-li:hover {
            background: none;
            box-shadow: none;
        }

        #flag-div{
            position: relative;

        }
        a.flag{
            display: inline-block;
            width: 48%;
            height: 24px;
            padding: 24px;
            background-position: center;


            @include breakpoint($nav-menu-show){
                display: block;
                padding: 0;
                width: 24px;
                
            }
        }
        
        &.nav-collapse {
            @include breakpoint($nav-menu-show){
            }
        }
    }
}


.responsive-menu {
    clear: both;
    ul {
        list-style-type: none;
    }
}

//MAIN CONTENT

ul.list li.list-box a.list-box-a {
        width: 188px;
        max-width: 100%;
}

.product-slide-toggle {
    width: 100%;
    display: none;
    @include breakpoint($sm){
        width: 189px;
    }
    @include breakpoint($lg){
        
    }
}

//TERMÉK VIEW
.prod-b {
    clear: both;
    margin-top: 25px;
    @include breakpoint ($sm){
        clear: none; // IE
        clear: initial;
        margin: 0;
    }
}

#slider {
    height: auto!important;
    height: initial!important;
}

/**********************
	footer
**********************/
#footer{
    background-color: #22376B;
    color: #ffffff;
    margin-top: 20px;
    position: relative;
}
#footer .footer-message {
    background: #fff;
    /* height: 18px; */
    /* max-width: 500px; */
    color: #000000;
    text-align: center;
    /* position: absolute;
    top: -18px;
    left: 50%;
    margin-left: -250px; */
}
#footer .footer-border{
    background: url("pic/footer-border.jpg") repeat-x scroll left bottom transparent;
    height: 10px;
}
#footer-inner{
    margin: 0 8px;
    max-width: 1000px;
    margin: auto;
    padding: 20px 8px;

    @include breakpoint($sm){
        display: flex;
        justify-content: space-between;
        
    }
    @include breakpoint(1016px){
        padding: 20px 0;
    }
}
#footer-inner .col{
/*		width: 292px;
    padding: 0 20px;*/
}
#footer-inner .col ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

#footer-inner .bottom-menu a:link, #footer-inner .bottom-menu a:visited{
    font-weight: bold;
    color: #FFF;
}

#EUCookie {
    background-color: rgba(0, 0, 0, 0.9);
    bottom: 0;
    color: #ffffff;
    display: none;
    font-family: "Droid Sans",sans-serif;
    left: 0;
    margin: 0;
    /* opacity: 0.8; */
    padding: 15px;
    position: fixed;
    right: 0;
}

.col {
    text-align: center;
    margin-bottom: 24px;
}

#footer-inner .col{
    @include breakpoint($sm){
        &:first-child {
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }
    }
}

//PRODUCT-PIC-SLIDER
.product-img{
    width: 100%;
    padding: 10px 5px;
    overflow: hidden;
    @include breakpoint($md){
        max-width: 60%;
    }
    @include breakpoint(861px){
        .flex-direction-nav .flex-prev {
            left: -100px
        }

        .flex-direction-nav .flex-next {
            right: -100px;
            text-align: right;
        }
    }
    #product-pic-slider,#product-pic-carousel{
        margin-bottom: 0;
        .flexslider:hover .flex-direction-nav .flex-next,.flexslider:hover .flex-direction-nav .flex-prev{
            opacity: 0.5;
        }
        .pic-parent .pic{
            cursor: pointer;
            display: block;
            width: 100%;

            padding-top: 70%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            @include breakpoint($nav-menu-show){
                padding-top: 54.96%;
            }
        }
    }
}
#slick-slider {
    padding: 0
}
.product-info-menu {
    width: 100%;
    @include breakpoint($md){
        width: 25%;
    }
}
.product-info-boxes {
    margin-top: 25px;
    width: 100%;
    @include breakpoint($md){
        width: 75%;
        margin-top: 0;
        padding: 0px 0px 0px 30px;
    }
}

.product-info .product-info-menu a{
	cursor: pointer;
	color: #354C83;
	font-weight: bold;
	display: block;
	padding: 15px 10px;
    border-bottom: 1px dotted;
    @include breakpoint($md){
        padding: 5px 10px;
    }
}

.login-box{
    background-color: #385292;
	color: #ffffff;
	display: none;
    padding: 15px;
    z-index: 10000;
    text-transform: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    @include breakpoint($nav-menu-show){
        position: absolute;
        top: 103px;
        left: auto;
        left: initial;
        right: 0;
        width: 320px;
        height: auto;
        height: initial;
    }
    .close-login {
        cursor: pointer;
        font-size: 20px;
        padding: 16px 24px;

        @include breakpoint($nav-menu-show){
            display: none;
        }
    }

    #login-form {
        margin-top: 40px;
        @include breakpoint($nav-menu-show){
            margin-top: 0;
            margin-top: initial;
        }
    }
}

.konf-sum-parent {
    width: 100%;
    @include breakpoint($sm){
        width: 522px;
    }
}
.product-config-sum-box {
    width: 100%;
    @include breakpoint($sm){
        width: 350px;
    }
}
.product-config-nyito img {
    width: 100%;
    // max-width: 100%
}

.pop-up {
    max-width: 100%;
    left: 0;
    margin-left: 0;
    @include breakpoint($nav-menu-show){
        left: 50%;
        margin-left: -170px;
    }
    &#opener-window {
        margin-left: 0;
        @include breakpoint($nav-menu-show){
            margin-left: -280px;
        }
    }
}

// MOBILIZED TABLE
.mobilized-table{
	@media screen and (max-width: $nav-menu-show){
		display: block;
        border-width: 0;
        max-width: 100%;
		tbody{
			display: block;
            border-width: 0;
		}
		tr{
			display: block;
			position: relative;
            margin-bottom: 2.5em;
			td{
				display: block;
                min-height: 2.4em;
                text-align: right;
			}
		}

		th{
			display: none;

		}
		td:before {
		    content: attr(data-th);
		    float: left;
		    text-align: left;
		    display: block;
		    margin-right: 5px;
            color: rgb(101, 101, 101);
            font-size: small;
		}
        .func {
            padding-right: 46px;
        }
    }
    .nonMobile {
        td:empty {
            display: none;
            @include breakpoint($nav-menu-show){
                display: table-cell;
            }
        }
    }
}

.cart-item {
    .modify-td {
        position: relative!important;
        br {
            display: none;
        }
        @include breakpoint($nav-menu-show){
            position: absolute;
            br {
                display: inline;
            }
        }

    }
}

.cart-table {
    @include breakpoint($nav-menu-show){
        margin-bottom: 40px;
    }
}

.narrow-form {
    @include breakpoint($nav-menu-show){
        width: 475px!important;
    }
}

.mobile-full {
    width: 100%;
    @include breakpoint($sm){
        width: auto;
        width: initial;
    }
}

.maxfull {
    max-width: 100%;
}

.text-div {
    max-width: 100%;
}

.flex-widen {
    display: flex;
    justify-content: space-between;
}

.sum-price {
    float: right;
    margin-bottom: 40px;
    @include breakpoint($nav-menu-show){
        width: 40%;
    }
}

.reg-form {
    .text-div-info {
        margin-left: 0;
        display: block;
        @include breakpoint(470px){
            margin-left: 140px;
        }
    }
}

.product-config-datas-row .last {
    width: 100%!important;
    clear: both;
	@include breakpoint($sm){
        width: 150px!important;
        clear: none;
    }
}
.order-menu {
    width: 100%;
    @include breakpoint($sm){
        width: auto;
        width: initial;
    }
}
.order-step {
    color: #2C2C2E;
    margin-right: 20px;
    display: block;
    margin: 10px 0;
    @include breakpoint($sm){
        display: inline;
        display: initial;
    }
}

.select-order-address span.gray-button {
    margin-bottom: 10px;
    @include breakpoint($nav-menu-show){
        margin-bottom: 0; // IE
        margin-bottom: initial;
    }
}

.addressForm {
    margin-bottom: 40px;
    @include breakpoint($sm){
        margin-right: 40px;
    }
}

.kosar-db {
    @include breakpoint($nav-menu-show){
        top: 8px;
        right: 25px;
    }
}

.vasarlasaim-sub-table {
    & [data-th="Mennyiség"] {
        text-align: right!important;
        @include breakpoint($nav-menu-show){
            text-align: center!important;
        }
    }
}

.hiddenmobile {
    display: none!important;
    @include breakpoint($nav-menu-show){
        display: table-cell!important;
    }
}

.subparent {
    background: #d4d4d4;
    @include breakpoint($nav-menu-show){
        background: none;
    }
    & > td {
        &:before {
            display: none!important;
        }
    }
}

.pag-button2 {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    opacity: 0.9;
    @include breakpoint($sm){
        width: 16px;
        height: 16px;
    }
    &.disable {
        opacity: 0.3;
    }
    &.next {
        background: url('pic/triangle-right.svg') no-repeat 0 0 transparent;
        background-size: contain;
    }
    &.last {
        background: url('pic/next-right.svg') no-repeat 0 0 transparent;
        background-size: contain;
    }
    &.prev {
        background: url('pic/triangle-right.svg') no-repeat 0 0 transparent;
        background-size: contain;
        transform: rotate(180deg);
    }
    &.first {
        background: url('pic/next-right.svg') no-repeat 0 0 transparent;
        background-size: contain;
        transform: rotate(180deg);
    }
}

.pag-num {
    opacity: 0.9;
    padding: 8px;
    @include breakpoint($sm){
        padding: 1px 5px;
    }
}

.pagination-block select{
    margin: 0 0 10px 10px;
	background-color: #ffffff;
    color: #2C2C2E;
    @include breakpoint($sm){
        margin: 0 0 0 10px;
    }
}

.pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include breakpoint($sm){
        width: auto;
        width: initial;
        display: block;
    }
}

#container>iframe {
    max-width: 100%;
}
#container>p>img {
    max-width: 100%
}
#container>table>tbody>tr>td>img {
    max-width: 90vw;
    height: auto!important;
    height: initial!important;
}
#container>table>tbody>tr>td>img {
    max-width: 90vw;
    height: auto!important;
    height: initial!important;
}
#container>table {
max-width: 100%;
width: auto;
width: initial;
}